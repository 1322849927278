<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_green_logo_horizontal.svg"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen"  style="height:80vh;">
      <div class="m-auto">

        <img
          src="@/assets/images/relatus_green_logo.svg"
          class="m-auto"
          style="height: 100px;"
        >

        <div style="margin-top: 30px;">
          <h3 class="text text-center">
            What plan would you like?
          </h3>

          <p class="text text-center">
            Please select the plans listed below
          </p>
        </div>

        <div class="flex mt-7 plans">
          <div class="box-content p-9 mr-3 border-2" :class="{selected: selectedPlan === 'freemium' }" @click="selectPlan('freemium')">
            <h3 class="border-b-2 pb-4">Freemium</h3>

            <p class="pt-4 pb-4">What's Included?</p>
            <ul>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
            </ul>
          </div>

          <div class="box-content p-9 mr-3 border-2" :class="{selected: selectedPlan === 'pro' }" @click="selectPlan('pro')">
            <h3 class="border-b-2 pb-4">Pro Plan</h3>

            <p class="pt-4 pb-4">What's Included?</p>
            <ul>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
            </ul>
          </div>

          <div class="box-content p-9 border-2" :class="{selected: selectedPlan === 'enterprise' }" @click="selectPlan('enterprise')">
            <h3 class="border-b-2 pb-4">Enterprise</h3>

            <p class="pt-4 pb-4">What's Included?</p>
            <ul>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
              <li class="text-sm mb-3"><img src="@/assets/images/ic_check_outlined.svg" class="inline" /> Unlimited Network Ingestion</li>
            </ul>
          </div>
        </div>

        <div class="mt-7 text-center">
          <a
            href="#"
            class="btn btn-white align-self-center me-auto mt-8 mr-3"
            style="color: #83C03B!important;border-color: #83C03B!important;"
          >
            Learn More
          </a>

          <router-link
            :to="{name: 'registration.plans.selected'}"
            class="btn btn-primary"
            style="background-color: #83C03B!important;border-color: #83C03B!important;"
          >
            Select Plan
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RegistrationPlans',
  data() {
    return {
      selectedPlan: null
    };
  },
  methods: {
    selectPlan: function(plan) {
      this.selectedPlan = plan;
    }
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 40px;
  color: #1E1E1E;
  letter-spacing: 1px;
  font-weight: 700;
}
p {
  font-family: 'Helvetica';
  font-size: 24px;
  line-height: 30px;
  color: #1E1E1E;
  margin-bottom: 0;
  font-weight: 300;
}
.sign-up .social-links {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 45px;
  padding: 10px;
  min-height: 50px;
}
.sign-up .social-links img {
  height: 24px;
}

/* FIX: Input must be a component */
input {
  background: #F9F9F9!important;
  color: #83C03B!important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  min-width: 250px;
}
input::placeholder {
  color: #83C03B!important;
  opacity: 1!important;
}
input:-ml-input-placeholder {
  color: #83C03B!important;
}
input::-ml-input-placeholder {
  color: #83C03B!important;
}
.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
.alert-error {
  background: rgba(217, 54, 31, 0.3);
  border-radius: 10px;
  padding: 10px;
}
.alert-error p {
  font-size: 14px;
}
a.btn-white {
  background-color: transparent;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 35px;
}
.plans .box-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
}
.plans .box-content.selected {
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
}
</style>
